<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Market Rate Audits</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import marketRates from '@/services/marketRates'
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import { datePredefined } from '@/utils/predefined'
import { currencyFilter } from '@/utils/currency'

const columns = [
  {
    _t_id: '1f8bfd47',
    prop: 'marketRateAuditId',
    text: 'Audit ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'marketRateAuditId',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: 'bf97700e-8dc3-4aec-9bf9-378565d26516',
    prop: 'marketplace',
    text: 'Rate',
    sort: true,
    filter: true,
    customFilterTabDisplay: (marketplace) =>
      marketplace ? 'CharterUP' : 'CoachRail',
    sortProp: 'marketRate/marketplace',
    type: 'number',
    filterType: 'eq',
    defaultSort: true,
    detail: false,
    computedText: (marketplace) =>
      marketplace == 1 ? 'CharterUP' : 'CoachRail',
    predefined: [
      {
        text: 'CharterUP',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        value: 1,
      },
      {
        text: 'CoachRail',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        value: 0,
      },
    ],
  },
  {
    _t_id: '2795495b',
    prop: 'companyName',
    text: 'Company',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'user/company/name',
    filterProp: 'user/company/name',
    detail: false,
  },
  {
    _t_id: 'd1f2a1f0',
    prop: 'fieldName',
    text: 'Rate Type',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'fieldName',
    detail: false,
  },
  {
    _t_id: 'c9789d65',
    prop: 'vehicleType',
    text: 'Vehicle Type',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'marketRate/vehicleType/label',
    filterProp: 'marketRate/vehicleType/label',
    detail: false,
  },
  {
    _t_id: '69c2638f',
    prop: 'vehicleName',
    text: 'Vehicle Name',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    detail: false,
  },
  {
    _t_id: '54bc58be',
    prop: 'actionType',
    text: 'Action Type',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    detail: false,
  },
  {
    _t_id: 'ecf672c7',
    prop: 'previousValue',
    text: 'Previous Rate',
    sort: false,
    filter: false,
    type: 'text',
    filterType: 'contains',
    sortProp: 'previousValue',
    detail: false,
  },
  {
    _t_id: '41b7a393',
    prop: 'newValue',
    text: 'New Rate',
    sort: false,
    filter: false,
    type: 'text',
    detail: false,
  },
  {
    _t_id: 'a4701608-f255-11ec-b939-0242ac120002',
    prop: 'charterUPRate',
    text: 'Market Rate',
    sort: true,
    filter: false,
    type: 'text',
    detail: false,
    computedText: (item) => currencyFilter(item),
  },
  {
    _t_id: '74f63a62-6918-408b-af20-183c00892794',
    prop: 'nearestMarketName',
    text: 'Market',
    sort: true,
    type: 'text',
    detail: false,
  },
  {
    _t_id: '1f989a87',
    prop: ['firstName', 'lastName'],
    text: 'User',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'userId',
    detail: false,
  },
  {
    _t_id: 'fba5dee1',
    prop: 'datetime',
    text: 'Date',
    sort: true,
    filter: true,
    type: 'date',
    filterType: 'eq',
    sortProp: 'datetime',
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy h:mm a'),
    predefined: deepClone(datePredefined),
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Market Rate Audit',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        detailKeyId: 'marketRateAuditDetail',
        tableId: 'market_rate_audit_tv',
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns,
        collection: 'marketRateAudit',
        action: (params) => marketRates.auditTableView(params),
        enableExport: this.canExport,
        exportServiceAction: (params) => marketRates.auditTableExport(params),
        exportName: 'MarketRateAudits.csv',
      },
    }
  },
  methods: {
    canExport() {
      const roles = this.currentUserProfile?.roles || []
      const canExportRole = roles.find(
        (r) => r.roleName === 'can_export_rate_audit'
      )
      return !!canExportRole
    },
  },
}
</script>
